import React from 'react';
import styled, { keyframes } from 'styled-components';

const App = () => {
  return (
    <Container>
      <Title>Coming Soon</Title>
      <Subtitle>We're working hard to bring you something awesome!</Subtitle>
      {/* <Image src={pandaImage} alt="Panda" /> */}
    </Container>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  animation: ${fadeIn} 2s ease-in-out;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #333;
  margin-bottom: 0.5rem;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 2rem;
`;

const Image = styled.img`
  max-width: 300px;
  width: 100%;
  border-radius: 50%;
`;

export default App;
